<template>
  <div class="books-page">
    <v-row justify="center">
      <v-col md="6">
        <v-card class="mx-3 mt-3 py-5 px-3" elevation="0" color="primary">
          <h3 class="white--text">
            Hi {{ currentUser.full_name.split(" ")[0] }},<br />What would you
            like to learn today?
          </h3>
        </v-card>
        <intract-smart-list
          ref="subjects-smart-list"
          disable-search
          v-if="subjectsEndpoint"
          :endpoint="subjectsEndpoint"
        >
          <template v-slot:list-item="{ item: subject }">
            <v-list-item
              @click="
                $router.push({
                  name: 'All' + routeName + 'Topics',
                  params: {
                    subjectId: subject.id,
                    subjectName: subject.name,
                  },
                })
              "
            >
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium">{{
                  subject.name
                }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  subject.room ? subject.room.sections : "Extra Subject"
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon
                ><v-icon>mdi-arrow-right</v-icon></v-list-item-icon
              >
            </v-list-item>
            <v-divider class="mx-4"></v-divider>
          </template>
        </intract-smart-list>
      </v-col>
    </v-row>

    <choose-room-dialog
      :visible="showRoomDialog"
      @roomSelected="
        (r) => {
          selectedRoom = r;
          showRoomDialog = false;
        }
      "
    />
  </div>
</template>
<script>
import Mixins from "@utils/mixins";
import IntractSmartList from "@components/generics/IntractSmartList";
// import Mixins from '@utils/mixins';
import { mapActions, mapGetters } from "vuex";
import ChooseRoomDialog from "@components/dialogs/ChooseRoomDialog";
import EventBus from "@utils/event_bus";
export default {
  name: "Books",
  mixins: [Mixins.essentials, Mixins.pullToRefresh, Mixins.handleAppBarOptions],
  data() {
    return { 
      routeName: this.$route.meta.is_activity ? 'ActivitySheet' : 'Book',
      subjects: [], 
      selectedRoom: null, 
      showRoomDialog: false, 
    };
  },
  components: {
    ChooseRoomDialog,
    IntractSmartList,
  },
  computed: {
    ...mapGetters(["currentUser", "accessToken"]),
    
    essentials() {
      return {
        accessToken: this.accessToken,
        handleErrorsFunction: this.handleApiError,
        setLoading: this.setLoading,
      };
    },
    shouldProceed() {
      return (
        ((this.currentUser.is_admin || this.currentUser.is_student) &&
          this.selectedRoom) ||
        (this.currentUser.is_faculty && !this.currentUser.is_admin)
      );
    },
    subjectsEndpoint() {
      if (!this.shouldProceed) return null;
      if (this.currentUser.is_student || this.currentUser.is_admin)
        return this.Helper.addUrlParams(
          this.endpoints.subjectViewSet,
          "room=" + this.selectedRoom.id
        );
      // if faculty
      return this.Helper.addUrlParams(
        this.endpoints.subjectViewSet,
        "faculty=" + this.currentUser.id
      );
    },

    appBarOptions() {
      return {
        // iconButton: { 
        //   icon: "mdi-qrcode-scan", 
        //   action: () => {
        //       this.$router.push({
        //           name: "BooksQRScanner",
        //       });
        //   }, 
        // },
      }
    },


  },
  methods: {
    ...mapActions(["showSnackbar", "setLoading"]),
    async onRefresh() {
      if (this.$refs["subjects-smart-list"])
        this.$refs["subjects-smart-list"].getItems();
    },
    // async getAllSubjects() {
    //   var subjectsEndpoint = endpoints.subjectViewSet;
    //   if(currentUser.is_admin) subjectsEndpoint = Helper.addUrlParams(subjectsEndpoint, ['room=' + this.selectedRoom.id]);
    //   else if(currentUser.is_faculty) subjectsEndpoints = Helper.addUrlParams(subjectsEndpoints, ['faculty=' + this.currentUser.id])
    //   var url = Helper.addUrlParams(
    //     endpoints.subjectViewSet,
    //     "room=" + this.selectedRoom.id
    //   );
    //   this.subjects = await api.call(this.essentials, url);
    // },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name != "All" + this.routeName + "Topics") {
      // this.$destroy();
      EventBus.$emit("keep_alive__destroy_view", "Books");
    }
    next();
  },
  created() {
    this.showRoomDialog = this.currentUser.is_admin; //only show room dialog when user is admin
  },
};
</script>
<style scoped>
.greeting-box {
  border-radius: 15px;
  color: white;
}
</style>